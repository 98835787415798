import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, vModelSelect as _vModelSelect, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vModelText as _vModelText, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeStyle as _normalizeStyle } from "vue";
const _hoisted_1 = {
  style: {
    "width": "70vw",
    "flex-wrap": "wrap"
  },
  class: "flex-start"
};
const _hoisted_2 = {
  style: {
    "width": "100%"
  },
  class: "flex-between"
};
const _hoisted_3 = {
  style: {
    "width": "100%"
  }
};
const _hoisted_4 = {
  style: {
    "width": "100%"
  }
};
const _hoisted_5 = {
  style: {
    "width": "100%"
  }
};
const _hoisted_6 = ["onUpdate:modelValue", "disabled"];
const _hoisted_7 = ["value"];
const _hoisted_8 = ["disabled"];
const _hoisted_9 = ["value"];
const _hoisted_10 = {
  style: {
    "width": "100%"
  }
};
const _hoisted_11 = {
  style: {
    "width": "100%"
  }
};
const _hoisted_12 = ["disabled"];
const _hoisted_13 = {
  class: "flex-start",
  style: {
    "width": "100%",
    "flex-wrap": "wrap"
  }
};
const _hoisted_14 = {
  style: {
    "width": "100%"
  }
};
const _hoisted_15 = {
  class: "flex-between",
  style: {
    "width": "100%"
  }
};
const _hoisted_16 = ["title"];
const _hoisted_17 = ["disabled"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_MultipleOption = _resolveComponent("MultipleOption");

  const _component_MultipleSelect = _resolveComponent("MultipleSelect");

  const _component_Modal = _resolveComponent("Modal");

  return _openBlock(), _createBlock(_component_Modal, {
    show: $data.modalShow,
    "onUpdate:show": _cache[6] || (_cache[6] = $event => $data.modalShow = $event)
  }, {
    title: _withCtx(() => [_createTextVNode(_toDisplayString(_ctx._t('ticket')), 1)]),
    body: _withCtx(() => [_createElementVNode("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, _toDisplayString(_ctx._t('questionType')), 1), _createElementVNode("div", _hoisted_5, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.nav, (_nav, _navIndex) => {
      return _withDirectives((_openBlock(), _createElementBlock("select", {
        "onUpdate:modelValue": $event => $data.nav[_navIndex] = $event,
        disabled: $props.question,
        class: "border-hint"
      }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getQuestionTypeByParentId($data.nav[_navIndex - 1] && $data.nav[_navIndex - 1].id || 0), _type => {
        return _openBlock(), _createElementBlock("option", {
          value: {
            id: _type.id,
            title: _type.title
          }
        }, _toDisplayString(_type.title), 9, _hoisted_7);
      }), 256))], 8, _hoisted_6)), [[_vModelSelect, $data.nav[_navIndex]]]);
    }), 256)), _ctx.getQuestionTypeByParentId($data.nav[$data.nav.length - 1] && $data.nav[$data.nav.length - 1].id || 0).length ? _withDirectives((_openBlock(), _createElementBlock("select", {
      key: 0,
      class: "border-hint",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.newQuestionType = $event),
      onChange: _cache[1] || (_cache[1] = $event => $options.changeAddNav()),
      disabled: $props.question
    }, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.getQuestionTypeByParentId($data.nav[$data.nav.length - 1] && $data.nav[$data.nav.length - 1].id || 0), _type => {
      return _openBlock(), _createElementBlock("option", {
        value: _type
      }, _toDisplayString(_type.title), 9, _hoisted_9);
    }), 256))], 40, _hoisted_8)), [[_vModelSelect, $data.newQuestionType]]) : _createCommentVNode("", true)])]), _createElementVNode("div", _hoisted_10, [_createElementVNode("div", _hoisted_11, _toDisplayString(_ctx._t('question')), 1), _withDirectives(_createElementVNode("input", {
      class: "border-hint",
      "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.questionTitle = $event),
      style: {
        "width": "100%"
      },
      placeholder: "input your question here...",
      disabled: $props.question
    }, null, 8, _hoisted_12), [[_vModelText, $data.questionTitle]])])]), _createElementVNode("div", _hoisted_13, [_createElementVNode("div", _hoisted_14, _toDisplayString(_ctx._t('item')), 1), _createVNode(_component_MultipleSelect, {
      style: {
        "width": "50%",
        "height": "2rem"
      },
      modelValue: $data.itemIdArray,
      "onUpdate:modelValue": _cache[3] || (_cache[3] = $event => $data.itemIdArray = $event)
    }, {
      default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.item, (_item, _itemIndex) => {
        return _openBlock(), _createBlock(_component_MultipleOption, {
          value: _item.id
        }, {
          default: _withCtx(() => [_createTextVNode(_toDisplayString(_item.name) + _toDisplayString(_item.description ? '(' + _item.description + ')' : ''), 1)]),
          _: 2
        }, 1032, ["value"]);
      }), 256))]),
      _: 1
    }, 8, ["modelValue"])])])]),
    footer: _withCtx(() => [_createElementVNode("div", _hoisted_15, [_createElementVNode("div", {
      style: _normalizeStyle([{
        visibility: $data.hint != '' ? 'visible' : 'hidden'
      }, {
        "padding": "0.2em 1em",
        "background-color": "orange",
        "color": "#fff",
        "border": "1xp solid #999",
        "border-radius": "4px",
        "width": "90%",
        "flex-wrap": "wrap"
      }]),
      class: "flex-between title-self",
      title: $data.hint
    }, [_createTextVNode(_toDisplayString($data.hint) + " ", 1), _createElementVNode("button", {
      class: "btn-close",
      onClick: _cache[4] || (_cache[4] = $event => $data.hint = '')
    })], 12, _hoisted_16), _createElementVNode("button", {
      class: "btn btn-self",
      style: {
        "color": "#0c0"
      },
      disabled: $data.ticketDisabled,
      onClick: _cache[5] || (_cache[5] = $event => $options.clickAddQuestionRecord())
    }, _toDisplayString(_ctx._t('submit')), 9, _hoisted_17)])]),
    _: 1
  }, 8, ["show"]);
}