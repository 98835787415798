import { mapActions, mapGetters, mapState } from 'vuex';
export default {
  inject: ['identify'],

  data() {
    //新增
    isLoginNew: false;

    return {
      isusernav: false,
      isnavopne: false,
      activeNames: ['1', '2', '3'],
      // 默认二级导航展开
      shopUrl: ''
    };
  },

  components: {},
  computed: { ...mapState('moblie', {
      user_info: 'user_info',
      navText: 'navText',
      navshopitem: 'navshopitem',
      shopping_cart_num: 'shopping_cart_num'
    }),
    ...mapGetters('moblie', {
      navshopitem: 'getNavShopItem'
    }),
    ...mapState({
      user: 'user',
      userType: 'userType'
    }),

    islogin() {
      let isLogin = false; // debugger

      if (this.userType === 'user' && !_.isEmpty(this.user)) {
        isLogin = true;
      }

      return isLogin;
    }

  },

  mounted() {
    // this.identify()
    this.backIdentify(); // debugger
    //10.8
    // if (this.userType !== 'user' || _.isEmpty(this.user)) {
    // 	this.identify()
    // }
    //10.8

    this.getCartNumber();
    const queryString = new URLSearchParams(window.location.search);
    const shopUrl = queryString.get('shopUrl');

    if (!shopUrl || shopUrl == null) {
      this.shopUrl = localStorage.getItem('shopUrl');
      return;
    } else {
      localStorage.setItem('shopUrl', shopUrl);
    }

    this.shopUrl = shopUrl;
  },

  watch: {
    isnavopne: {
      handler(nv, ov) {
        this.$store.commit('isnavopne', nv);
      }

    }
  },
  methods: { ...mapActions('moblie', ['getCartNumber', 'logOut']),

    //10.10
    backIdentify() {
      const it = this; // var url = '/api/' + this.userType + '/Base/identify'

      var url = '/api/user/Base/identify'; // var url = '/api/admin/Base/identify'

      it.$axios.post(url, {}).then(res => {
        if (res.data.errno) {} else {
          it.$store.commit('changeUser', res.data.data); // it.login()
          // it.loginModalShow = false
        }
      }).catch(err => {
        console.log(err);
      });
    },

    //10.10
    openDown() {
      this.isusernav = !this.isusernav;
    },

    navTextfun(navText) {
      this.navText = navText; // console.log(this.navText)
    },

    toggleLanguages(local) {},

    clickLogin() {
      // const href = this.navText.login + '?bkurl=' + window.location.href
      const href = this.shopUrl + '/mobileview/login.html' + '?bkurl=' + window.location.href;
      window.location.href = href;
    },

    clickRegister() {
      if (this.shopUrl) {
        location = this.shopUrl + '/mobileview/register.html';
      }
    },

    clickToMy() {
      if (this.shopUrl) {
        location = this.shopUrl + '/mobileview/my.html';
      }
    },

    clickToMyOrder() {
      if (this.shopUrl) {
        location = this.shopUrl + '/mobileview/my-order.html';
      }
    },

    async clickLogout() {
      // this.$store.commit('changeUser', '')
      await this.logOut();

      if (this.shopUrl) {
        location = this.shopUrl + '/mobileview/login.html';
      }
    },

    clickToCart() {
      if (this.shopUrl) {
        location = this.shopUrl + '/mobileview/cart.html';
      }
    }

  }
};