import { mapGetters, mapState } from 'vuex';
import Modal from './Modal.vue';
import MultipleSelect from './MultipleSelect.vue';
import MultipleOption from './MultipleOption.vue';
export default {
  components: {
    Modal,
    MultipleSelect,
    MultipleOption
  },
  props: {
    show: {
      type: Boolean,

      default() {
        return false;
      }

    },
    questionType: {
      type: Object,

      default() {
        return undefined;
      }

    },
    question: {
      type: Object,

      default() {
        return undefined;
      }

    },
    questionRecord: {
      type: String,

      default() {
        return undefined;
      }

    }
  },

  data() {
    return {
      modalShow: false,
      nav: [],
      newQuestionType: {},
      questionTitle: '',
      itemIdArray: [],
      hint: '',
      ticketDisabled: false
    };
  },

  watch: {
    show: function (nv, ov) {
      this.modalShow = nv;

      if (nv) {
        //this.getOrderByUser();
        if (this.questionType) {
          this.nav = this.questionType && this.questionType.nav || [];
        }

        if (this.question) {
          this.nav = this.question && this.question.nav || [];
        }

        this.questionTitle = this.question && this.question.title;
      }
    },
    modalShow: function (nv, ov) {
      this.$emit('update:show', nv);
    }
  },
  computed: { ...mapState({
      order: 'order',
      item: 'item'
    }),
    ...mapGetters({
      getQuestionTypeNavShow: 'getQuestionTypeNavShow',
      getQuestionNavShow: 'getQuestionNavShow',
      itemByName: 'getItemByName',
      getQuestionTypeByParentId: 'getQuestionTypeByParentId'
    })
  },

  activated() {
    this.hint = '';
  },

  methods: {
    clickAddQuestionRecord() {
      this.ticketDisabled = true;
      console.log('12312321');

      if (this.nav.length < 2) {
        this.hint = 'The level of QuestionType must > 1!';
        this.ticketDisabled = false;
        return;
      }

      if (_.isEmpty(this.questionTitle) || this.questionTitle.trim() == '') {
        this.hint = "The Question can't be empty!";
        this.ticketDisabled = false;
        return;
      }

      if (this.questionTitle.length > 100) {
        this.hint = "Please input question simple expression, more details description could be chat after submit a ticket.Make sure no more than 100 characters";
        this.ticketDisabled = false;
        return;
      }

      var questionTypeId = this.nav[this.nav.length - 1] && this.nav[this.nav.length - 1].id || 0;
      var params = {
        question_type_id: questionTypeId
      };

      if (this.question) {
        params.question_id = this.question.id;
      } else {
        params.title = this.questionTitle;
      }

      if (this.questionRecord) {
        params.question_record = this.questionRecord;
      }

      if (!_.isEmpty(this.itemIdArray)) {
        params.item_id_array = this.itemIdArray;
      } // this.$axios.post('/api/user/KnowledgeBase/addQuestionRecord',params).then((res)=>{
      //   if(!res.data.errno){
      //     this.modalShow=false;
      //     this.$msgbox({
      //       title:'Success',
      //       message:'Create ticket Successful! Will open a new window,if not,please check the setting of the browser is allowed to open a new window.',
      //       type:'success',
      //     })
      //     window.open('/user/chat');
      //   }else{
      //     if(res.data.errmsg=='Create Ticket in 5 minute again'){
      //       this.hint="Create Ticket failed,Please don't create many tickets in a short time.And you could click the 'After-Sales' to chat with us.";
      //     }
      //   }
      // }).catch((err)=>{
      //   console.log(err);
      // })

    },

    changeAddNav() {
      this.newQuestionType && this.nav.push({
        id: this.newQuestionType.id,
        title: this.newQuestionType.title
      });
      this.newQuestionType = undefined;
    }

  }
};